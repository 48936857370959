@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiCardHeader-root {
  padding: 0px !important;
  border-bottom: 1px solid #532363;
  /* background-color: #151B26; */
}

.MuiCardHeader-title {
  font-size: 1rem !important;
  padding: 6px 16px !important;
  color: #532363 !important;
  font-weight: 700;
}

.MuiCardHeader-action {
  margin-top: 0 !important;
  margin-right: 0 !important;
  align-self: stretch;
}

.MuiCardHeader-action button.MuiButton-outlined.MuiButton-sizeSmall {
  height: 100% !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: #dedede !important;
  padding: 6px 16px !important;
}

.MuiCardActions-root {
  border-top: 1px solid #ddd;
}

.MuiButton-label {
  text-transform: none !important;
}

.MuiGrid-grid-lg-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
}

.checklist-icon {
  margin-top: 6px;
}

.introjs-tooltip {
  min-width: 500px;
}
