.MuiCardContent-root > div:first-child {
    height: auto !important;
}

.MuiDataGrid-footer {
    border-top: 1px solid rgb(224, 224, 224);
}

div.MuiDataGrid-colCell[role="columnheader"] {
    background-color: #042366;
    color: white;
}

div.MuiDataGrid-colCell[role="columnheader"] svg {
    color: white;
}

.MuiDataGrid-colCellWrapper > div:last-child {
    display: none;
}

.MuiDialogActions-root button:first-child {
    color: #fff;
    background-color: #dc3545;
}

.MuiDialogActions-root button:last-child {
    color: #fff;
    background-color: #388e3c;
}

.MuiDialogContent-root {
    overflow-x: hidden;
}

#qrPaper svg {
    width: 100% !important;
    height: auto !important;
}